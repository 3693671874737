<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Year</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                name="year"
                rules="required"
              >
                <v-select
                  v-model="year"
                  :options="[
                    1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007,
                    2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017,
                    2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027,
                    2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
                    2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
                    2048, 2049, 2050, 2051, 2052,
                  ]"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Month</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                name="Month"
                rules="required"
              >
                <v-select
                  :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                  v-model="month"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>No of CP / Franchise</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="no of cp franchise"
              >
                <b-form-input
                  v-model="noofcpf"
                  placeholder="Enter No of CP / Franchise"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Employee</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee"
              >
                <v-select
                  :options="employeeOptions"
                  label="name"
                  v-model="employee"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Amount</label>
              <b-form-input v-model="amount" placeholder="Enter Amount" />
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";

import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      year: "",
      month: "",
      noofcpf: "",
      employee: "",
      amount: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      submitDisable: false,
      employeeOptions: [],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Target") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/target");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/target");
          }
        }
      });
    }
    this.getEmployee();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEmployee(id) {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/team`,
      }).then((response) => {
        this.employeeOptions = response.data.data.data;
        if (id !== undefined) {
          this.employeeOptions.map((item) => {
            if (item.id == id) {
              this.employee = item.name;
            }
          });
        }
      });
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/gettargetById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      data.map((item) => {
        this.year = year;
        this.month = month;
        this.noofcpf = noofcpf;
        this.employee = employee;
        this.amount = amount;
      });
    },
    submitForm(e) {
      const data = {
        branch: 1,
        company: 1,
        active: 1,
        year: this.year,
        month: this.month,
        noofcpf: this.noofcpf,
        employee: this.employee.id,
        amount: this.amount,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/target/${this.$route.params.id}`
              : `${baseApi}/target`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/crm/target");
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addRow() {
      this.documents.push({
        name: "",
        attachment: "",
      });
    },
    removeRow(index) {
      this.documents.splice(index, 1);
    },
    async handleChange(e, type, index) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("document", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.documents[index].attachment =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/crm/target");
    },
  },
};
</script>
